.categories {
	display: flex;
	padding-top: 3rem;
}

.category {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	&__icon-container {
		display: flex;
		align-items: center;
		justify-content: center;

		height: 3.2rem;
		width: 3.2rem;
		border-radius: 0.8rem;
		margin-bottom: 1rem;

		&--1 {
			background-color: var(--color-warning-1);
		}

		&--2 {
			background-color: var(--color-success-1);
		}

		&--3 {
			background-color: var(--color-error-1);
		}

		&--4 {
			background-color: var(--color-primary-1);
		}
	}

	&__icon {
		height: 2rem;
		width: 2rem;
	}

	&__title {
		@include link-underline;
	}

	&__title:link,
	&__title:visited {
		text-decoration: none;
		color: var(--color-black);
		padding-bottom: 0.4rem;
	}

	&__list {
		display: flex;

		font-size: 1.15rem;
		list-style: none;

		&--short {
			flex-wrap: wrap;
		}

		&-item {
			@include link-underline;
		}

		&-item:link,
		&-item:visited {
			text-decoration: none;
			color: var(--color-black);
		}
	}
}
