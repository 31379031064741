.home {
  display: flex;
  flex-direction: column;
  max-width: 90rem;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 8rem 0;
  overflow-x: hidden;

  transition: filter 0.3s;

  @media only screen and (max-width: $bp-600) {
    padding: 5rem 0;
  }
}

.heading {
  display: flex;
  flex-direction: column;
}

.heading-title {
  font-size: 8rem;
  line-height: 12rem;

  @media only screen and (max-width: $bp-900) {
    font-size: 6.5rem;
    line-height: 8rem;
  }

  @media only screen and (max-width: $bp-800) {
    font-size: 6rem;
    line-height: 7.5rem;
  }

  @media only screen and (max-width: $bp-600) {
    font-size: 5.5rem;
    line-height: 6.5rem;
  }
}

.heading-subtitle {
  font-family: "Courier New", Courier, monospace;
  font-weight: lighter;
  font-size: 3rem;
  margin-top: 5rem;

  color: var(--color-dark-1);

  @media only screen and (max-width: $bp-900) {
    font-size: 2.7rem;
  }

  @media only screen and (max-width: $bp-800) {
    font-size: 2.5rem;
  }

  @media only screen and (max-width: $bp-600) {
    font-size: 2rem;
  }
}

.heading-buttons {
  margin-top: 18rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: $bp-600) {
    margin-top: 12rem;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
  }

  .button:not(:last-child) {
    margin-right: 1.5rem;

    @media only screen and (max-width: $bp-600) {
      margin-right: 0;
      margin-bottom: 1.5rem;
    }
  }

  a {
    display: inline-block;
  }
}
