.story-info {
  display: flex;
  padding: 3.5rem 0 4rem;
  align-items: flex-end;
}

.info-icon {
  width: 2.1rem;
  height: 2.1rem;
  margin-right: 1.5rem;

  &--unread {
    fill: var(--color-dark-1);
  }

  &--read {
    fill: var(--color-primary);
  }
}

.overview {
  font-family: "Courier New", Courier, monospace;

  &__subtitle {
    text-transform: uppercase;
    font-size: 1.3rem;
    margin-bottom: 1rem;
    color: var(--color-subtext);
  }

  &__title {
    font-size: 1.6rem;
    color: var(--color-text);
  }
}

.empty {
  display: flex;
  align-items: flex-end;
  padding: 3.5rem 0 4rem;
  border-top: var(--line);

  &-icon {
    height: 2.4rem;
    width: 2.4rem;
    margin-right: 1.5rem;
  }

  &-overview {
    display: flex;
    flex-direction: column;

    &__subtitle {
      width: 25rem;
      height: 1.4rem;
      margin-bottom: 1rem;
      background-color: gainsboro;

      border-radius: 0.7rem;
      padding: 1rem;
    }

    &__title {
      width: 15rem;
      height: 1.6rem;
      background-color: var(--color-subtext);

      border-radius: 0.7rem;
      padding: 1.2rem;
    }
  }
}
