.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-box {
  display: flex;
  flex-direction: column;

  background-color: var(--color-base-ground);
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);

  padding: 3rem;
  margin: 0 2rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.5);
  border-radius: 2rem;

  &__header {
    font-size: 3rem;
  }

  &__subheader {
    font-family: "Courier New", Courier, monospace;
    font-size: 1.6rem;
    margin-top: 0.8rem;

    color: var(--color-dark-1);
  }

  &__label {
    font-family: "Courier New", Courier, monospace;
    font-size: 1.6rem;
    margin: 5rem 0 0.6rem;
    color: var(--color-text);
  }

  &__input {
    padding: 1.4rem;
    margin: 0 0 6rem;
    border-radius: 1.1rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.08);
    color: var(--color-text);

    font-size: 1.6rem;

    &:focus {
      outline: none;
      border: 0.1rem solid rgba(0, 0, 0, 0.17);
    }

    &--correct {
      border: 0.1rem solid rgba(0, 150, 0, 0.3) !important;
      background-color: rgba(200, 255, 200, 0.3);
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: $bp-600) {
      flex-direction: column;
      align-items: stretch;
      text-align: center;
    }

    .button:not(:last-child) {
      margin-right: 1.5rem;

      @media only screen and (max-width: $bp-600) {
        margin-right: 0;
        margin-bottom: 1.5rem;
      }
    }
  }
}
