.body {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100vw;
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;

  margin-bottom: 20rem;

  @media only screen and (max-width: $bp-1100) {
    padding: 0 4rem;
  }

  @media only screen and (max-width: $bp-600) {
    padding: 0 3rem;
  }

  @media only screen and (max-width: $bp-500) {
    padding: 0 2rem;
  }
}
