.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  overflow-x: hidden;

  max-width: 90rem;
  padding: 3rem 0;

  color: var(--color-text);
  font-size: 1.5rem;
  font-family: "Courier New", Courier, monospace;
  border-radius: 0 0 2rem 2rem;

  transition: filter 0.3s;

  &__end {
    cursor: pointer;

    display: flex;
    align-items: center;
  }

  &__item {
    @include link-underline;

    &:not(:last-child) {
      margin-right: 4rem;
    }

    &:link,
    &:visited {
      color: var(--color-text);
      text-decoration: none;
    }
  }

  &__item-icon {
    fill: white;
    height: 2.4rem;
    width: 2.4rem;
    fill: var(--color-dark-1);

    &--locked {
    }

    &--unlocked {
      fill: var(--color-primary);
    }
  }
}

.unlock-sign {
  height: 1rem;
  width: 1rem;
  border-radius: 1rem;
  margin-right: 1rem;
  margin-top: 0.5rem;

  &--locked {
    background-color: var(--color-primary-2);
    box-shadow: 0 0 0.2rem 0.1rem red;
  }

  &--unlocked {
    background-color: green;
    box-shadow: 0 0 0.2rem 0.1rem green;
  }
}
