/* You can add global styles to this file, and also import other style files */
@import "assets/abstracts/functions";
@import "assets/abstracts/mixins";
@import "assets/abstracts/variables";

@import "assets/base/animations";
@import "assets/base/base";
@import "assets/base/typography";
@import "assets/base/utilities";

@import "assets/components/button";
@import "assets/components/category";
@import "assets/components/featured-card";
@import "assets/components/form";
@import "assets/components/heading-container";
@import "assets/components/notification";
@import "assets/components/popup";
@import "assets/components/section";
@import "assets/components/story-info-card";
@import "assets/components/story-list-item";

@import "assets/layout/footer";
@import "assets/layout/layout";
@import "assets/layout/nav";

@import "assets/pages/home";
@import "assets/pages/stories";
@import "assets/pages/story";
