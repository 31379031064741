@keyframes moveInLeft {
	0% {
		opacity: 0;
		transform: translateX(-10rem)
	}

	80% {
		transform: translateX(1rem)
	}

	100% {
		opacity: 1;
		transform: translate(0);
	}
}

@keyframes moveInRight {
	0% {
		opacity: 0;
		transform: translateX(10rem)
	}

	80% {
		transform: translateX(-1rem)
	}

	100% {
		opacity: 1;
		transform: translate(0);
	}
}

@keyframes moveInBottom {
	0% {
		opacity: 0;
		transform: translateY(10rem)
	}

	100% {
		opacity: 1;
		transform: translate(0);
	}
}

@keyframes pulsate {
	0% {
		transform: scale(1);
		box-shadow: none;
	}

	50% {
		transform: scale(1.05); 
		box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.25);
	}

	100% {
		transform: scale(1);
		box-shadow: none;
	}
}