.story {
  display: flex;
  flex-direction: column;

  max-width: 90rem;
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;

  font-family: "Courier New", Courier, monospace;

  transition: filter 0.3s;

  &__info {
    display: flex;
    border-bottom: var(--line);
    padding: 3.5rem 0;
    margin-bottom: 2rem;
    justify-content: space-between;

    @media only screen and (max-width: $bp-600) {
      flex-direction: column;
    }
  }
}

.story-text {
  font-size: 1.6rem;
  color: var(--color-text);
  line-height: 3rem;

  &__strike {
    text-decoration: line-through;
  }

  &__normal {
    margin-top: 2rem;

    &--right {
      text-align: right;
    }

    &--3degree {
      margin-top: 0;
      padding-left: 2rem;
      transform: rotate(-3deg);
    }

    &--2degree {
      padding-left: 2rem;
      transform: rotate(-2deg);
    }

    &--15degree {
      padding-left: 2rem;
      transform: rotate(-1.5deg);
    }

    &--1degree {
      padding-left: 2rem;
      transform: rotate(-1deg);
    }
  }

  &__flip {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  &__compact {
    margin-top: -0.5rem;
  }

  &__red {
    color: var(--color-primary);
  }
}

.read-icon {
  height: 2rem;
  width: 2rem;

  &--unread {
    fill: var(--color-dark-1);
  }

  &--read {
    fill: var(--color-primary);
  }
}
