.section {
  display: flex;
  flex-direction: column;

  background-color: var(--color-light-1);
  width: calc(100% - 6rem);
  max-width: 110rem;
  box-shadow: var(--shadow-dark);
  border-radius: 1.2rem;
  padding: 2rem 3rem 3rem;

  &--categories {
    margin-top: -8rem;
  }

  &--featured {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    padding-top: 5rem;
  }
}

// .heading {
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-end;

//   padding-bottom: 1.5rem;

//   &--with-line {
//     border-bottom: var(--line);
//   }

//   &--featured {
//     margin-bottom: 2rem;
//   }

//   &__link:link,
//   &__link:visited {
//     display: flex;
//     align-items: center;
//     font-size: 1.3rem;
//     font-weight: 700;
//     text-decoration: none;
//     color: var(--color-primary-5);
//   }

//   &__text {
//     margin-right: 1rem;
//   }

//   &__icon {
//     height: 1.3rem;
//     width: 1.6rem;
//     fill: currentColor;
//   }
// }
