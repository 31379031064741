app-story-info-card:not(:last-child) {
  margin-right: 3rem;

  @media only screen and (max-width: $bp-600) {
    margin-right: 0;
    margin-bottom: 3rem;
  }
}

.info-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__heading {
    font-size: 2rem;
    font-weight: normal;
    color: var(--color-text);
    margin-bottom: 0.4rem;
  }

  &__small-text {
    font-size: 1.4rem;
    color: var(--color-subtext);
  }

  &__download {
    font-size: 1.5rem;
    font-family: "Courier New", Courier, monospace;

    @include link-underline;

    &:link,
    &:visited {
      color: var(--color-subtext);
      text-decoration: none;
    }
  }
}
