.button {
  font-size: 1.6rem;
  font-family: "Courier New", Courier, monospace;
  font-weight: 700;
  text-decoration: none;
  color: var(--color-text);

  border: none;
  border-radius: 1rem;
  padding: 1.7rem 3.2rem;
  cursor: pointer;

  transition: all 0.2s;

  @media only screen and (max-width: $bp-600) {
    padding: 1.5rem 2.5rem;
  }

  &--primary {
    color: white;
    background-color: var(--color-primary);

    &:hover {
      background-color: var(--color-primary-2);
    }

    &:active {
      background-color: var(--color-primary);
    }
  }

  &--primary-washed {
    color: white;
    background-color: var(--color-primary-border);
  }

  &--boring {
    background-color: var(--color-boring);

    &:hover {
      background-color: var(--color-boring-hover);
    }

    &:active {
      background-color: var(--color-boring);
    }
  }

  &--icon {
    border-radius: 10rem;
    padding: 0;
    padding-top: 0.5rem;
    padding-left: 0.1rem;
    height: 4rem;
    width: 4rem;
    background-color: transparent;

    &:hover {
      background-color: rgba(0, 0, 0, 0.15);
    }
  }

  &--blanco {
    @include link-underline;
  }

  &:focus {
    outline: none;
  }
}
