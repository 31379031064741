@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin deadcenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin link-underline {
  display: inline-block;

  &::after {
    content: "";
    width: 0px;
    height: 0.2rem;
    display: block;
    background-color: var(--color-primary);
    transition: 0.25s;
  }

  &:hover::after {
    width: 100%;
  }
}

/* Media query manager
> 0 <= 600px		Phone
> 600 <= 900px		Tablet portrait
> 900 <= 1200px		Tablet landscape
> 1200 <= 1800px	Normal
> 1800px			Big screen

$breakpoint argument choices
- phone
- tab-port
- tab-land
- big

Oder of working:
Base + typography > general layout + grid > page layout > components

1em = 16px
*/
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    } // 600px
  }

  @if $breakpoint == tablet {
    @media only screen and (max-width: 56.25em) {
      @content;
    } // 900px
  }

  @if $breakpoint == startup {
    @media only screen and (max-width: 81.25em) {
      @content;
    } // 1300 px
  }

  @if $breakpoint == big {
    @media only screen and (min-width: 112.5em) {
      @content;
    } // 1800 px
  }
}
