.footer {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	background-color: var(--color-primary);
	width: 100%;
	color: var(--color-white);
	font-size: 1.44rem;
	border-radius: 2rem 2rem 0 0;
	padding: 5rem 3rem;
	margin-top: 10rem;

	&__item {
		&:link,
		&:visited {
			color: var(--color-white);
			text-decoration: none;
			text-transform: uppercase;
			font-weight: 700;
		}
	}

	&__categories {
		width: 20%;
	}
}

.list {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin-top: 1.5rem;

	font-weight: 300;

	&__item {
		flex: 0 0 50%;
		margin-bottom: 0.7rem;

		& > a {
			&:link,
			&:visited {
				color: var(--color-white);
				text-decoration: none;
			}
		}
	}

	// &__item::before {
	// 	content: '';
	// 	display: inline-block;
	// 	height: 1rem;
	// 	width: 1rem;
	// 	margin-right: 0.7rem;

	// 	// Old shit
	// 	// background-image: url(../img/chevron-thin-right.svg);
	// 	// background-size: cover;

	// 	// New shit
	// 	background-color: var(--color-primary);
	// 	-webkit-mask-image: url(../img/chevron-thin-right.svg);
	// 	-webkit-mask-size: cover;
	// 	mask-image: url(../img/chevron-thin-right.svg);
	// 	mask-size: cover;
	// }
}
