.form {
	display: flex;
	flex-direction: column;

	&-field {
		display: flex;
		flex-direction: column;

		font-family: inherit;
		color: inherit;

		&__label {
			margin-bottom: 1rem;
		}

		&__input {
			background-color: white;
			border: none;
			border: 0.1rem solid transparent;
			border-radius: 0.8rem;
			display: block;
			padding: 1.25rem 1.35rem;
			transition: all 0.25s;
			font-size: 1.15rem;
			box-shadow: none; // Removes crazy Firefox error shadow

			&:focus {
				outline: none;
				border: 0.1rem solid var(--color-primary-5);

				&:invalid {
					border: 0.1rem solid var(--color-error-5);
				}
			}
		}

		&__captcha {
			margin-top: 1rem;
			width: 100%;
			border-radius: 0.8rem;
		}

		&::-webkit-input-placeholder {
			color: var(--color-light-5);
		}

		&:not(:last-child) {
			margin-bottom: 2.5rem;
		}

		&:last-of-type {
			margin-bottom: 4rem;
		}
	}

	&--signin,
	&--signup {
		margin-bottom: 1rem;
	}
}
