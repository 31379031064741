.features {
	display: flex;
	// flex-wrap: wrap;
	justify-content: space-between;
}

.featured-card {
	display: flex;
	flex-direction: column;

	box-shadow: var(--shadow-dark);
	border-radius: 0.8rem;
	background-color: var(--color-light-1);

	transition: all 0.2s;

	// &:hover {
	// 	transform: translateY(-10px);
	// 	box-shadow: var(--shadow-dark);
	// }

	&__photo {
		background-size: cover;
		height: 16rem;
		border-top-left-radius: 0.8rem;
		border-top-right-radius: 0.8rem;
	}

	&__listing-info,
	&__vendor-overview {
		display: flex;
		flex-direction: column;

		min-height: 12.5rem;
		padding: 1.3rem 2rem 1.5rem 2rem;
	}

	&__title {
		font-size: 1.4rem;
		font-weight: 700;
		@include link-underline;
	}

	&__link:link,
	&__link:visited {
		text-decoration: none;
		color: var(--color-black);
	}

	&__pricing {
		display: flex;
		align-items: flex-start;
		// font-family: Georgia, 'Times New Roman', Times, serif;
		margin-bottom: auto;
	}

	&__currency {
		font-size: 1.8rem;
		margin-right: 0.3rem;
		color: var(--color-dark-1);
	}

	&__price,
	&__sales {
		font-size: 2.8rem;
		font-weight: 100;
		margin-top: -0.5rem;
	}

	&__vendor {
		&-info {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 1.15rem;
		}

		&-link {
			@include link-underline;
		}

		&-link:link,
		&-link:visited {
			text-decoration: none;
			color: var(--color-black);
		}

		&-rating {
			color: var(--color-light-1);
			border-radius: 20px;
			padding: 0.2rem 0.8rem;

			&--new {
				background-color: var(--color-primary-4);
			}

			&--pro {
				background-color: var(--color-success-4);
			}
		}

		&-stats {
			display: flex;
			flex-direction: column;
		}

		&-reviews {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: auto;
			font-size: 1.15rem;
		}

		&-positive {
		}

		&-percentage {
			font-weight: 700;
		}

		&-progress {
			-webkit-appearance: none;
			appearance: none;
			border: none;
			border-radius: 30px;

			width: 100%;
			height: 0.8rem;
			margin-top: 0.5rem;

			&::-webkit-progress-bar {
				border-radius: 30px;
				background-color: var(--color-light-4);
			}

			&::-webkit-progress-value,
			&::-moz-progress-bar {
				border-radius: 30px;
			}

			&--perfect::-webkit-progress-value,
			&--perfect::-moz-progress-bar {
				background-color: var(--color-success-3);
			}

			&--good::-webkit-progress-value,
			&--good::-moz-progress-bar {
				background-color: var(--color-warning-3);
			}
		}
	}
}
