.heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: var(--line);

  & > .button {
    margin-top: 1.5rem;

    @media only screen and (max-width: $bp-800) {
      margin-top: 1rem;
    }

    @media only screen and (max-width: $bp-600) {
      margin-top: 0.5rem;
    }
  }

  &__title {
    margin-right: 2rem;

    font-size: 6rem;
    line-height: 13rem;

    @media only screen and (max-width: $bp-800) {
      font-size: 5rem;
      line-height: 10rem;
    }

    @media only screen and (max-width: $bp-600) {
      font-size: 4rem;
      line-height: 5rem;
      margin-bottom: 2rem;
      margin-top: 1rem;
    }
  }
}
