.notification {
	width: 100%;
	border-radius: 0.8rem;
	padding: 0.8rem 1.4rem;
	margin-bottom: 2rem;

	&--error {
		background-color: var(--color-error-1);
		color: var(--color-error-5);
		border: 0.1rem solid var(--color-error-2);
	}
}

.error-list {
	width: 100%;
}
