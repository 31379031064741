:root {
  --color-boring-light: rgba(0, 0, 0, 0.05);
  --color-boring-dark: rgba(255, 255, 255, 0.08);

  --color-boring-hover-light: rgba(0, 0, 0, 0.03);
  --color-boring-hover-dark: rgba(255, 255, 255, 0.1);

  --color-light-1: #ffffffff;
  --color-light-2: #f8f6f6ff;
  --color-light-3: #f0eeeeff;
  --color-light-4: #e6e6e6ff;
  --color-light-5: #ccccccff;
  --shadow-light: 0 1rem 1.5rem 0 rgba(0, 0, 0, 0.02);

  --color-dark-1: #999999ff;
  --color-dark-2: #777777ff;
  --color-dark-3: #6f6866ff;
  --color-dark-4: #292525ff;
  --color-dark-5: #1a1a1a;
  --color-dark-6: #231f34ff;
  --shadow-dark: 0 1rem 1rem 0 rgba(0, 0, 0, 0.03);
  --shadow-dark-hover: 0 0 0 1px rgba(67, 56, 56, 0.07),
    0 8px 14px 1px rgba(67, 56, 56, 0.15);

  --color-primary-1: #c42021ff;
  --color-primary-2: #db4444;
  --color-primary-border: #eeb2b2;
  --gradient-primary: linear-gradient(
    to left bottom,
    var(--color-primary-2),
    var(--color-primary-1)
  );
  --shadow-primary: 0 0.6rem 1.35rem 0 rgba(196, 32, 33, 0.35);
  --shadow-primary-hover: 0 0.8rem 1.5rem 0.2rem rgba(196, 32, 33, 0.25);

  --color-base-ground: var(--color-light-1);
  --color-off-ground: var(--color-light-2);
  --color-text: var(--color-dark-6);
  --color-subtext: var(--color-dark-1);
  --color-placeholder: var(--color-light-5);
  --color-primary: var(--color-primary-1);
  --color-boring: var(--color-boring-light);
  --color-boring-hover: var(--color-boring-hover-light);

  --line: 1px solid var(--color-light-4);
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-base-ground: var(--color-dark-4);
    --color-off-ground: var(--color-dark-5);
    --color-text: var(--color-light-3);
    --color-placeholder: var(--color-dark-1);
    --color-boring: var(--color-boring-dark);
    --color-boring-hover: var(--color-boring-hover-dark);
    --line: 1px solid var(--color-dark-3);
  }
}

$bp-1200: 75em; // 1200px
$bp-1100: 68.75em; // 1100px
$bp-900: 56.25em; // 900px
$bp-800: 50em; // 800px
$bp-600: 37.5em; // 600px
$bp-500: 31.25em; // 500px
$bp-440: 27.5em; // 440px
