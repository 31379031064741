.stories {
  display: flex;
  flex-direction: column;

  max-width: 90rem;
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;

  transition: filter 0.3s;

  &__input {
    font-family: "Courier New", Courier, monospace;
    font-size: 1.6rem;
    color: var(--color-text);
    background-color: transparent;
    border: none;
    padding: 1rem 0;
    margin: 5rem 0 4rem;

    @media only screen and (max-width: $bp-800) {
      margin: 2.5rem 0 2rem;
    }

    @media only screen and (max-width: $bp-600) {
      margin: 1rem 0 1.5rem;
    }

    &:focus {
      outline: none;
    }

    &::-webkit-input-placeholder {
      font-weight: lighter;
      color: var(--color-placeholder);
    }
  }
}

.message {
  font-family: "Courier New", Courier, monospace;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 3rem 0;

  &__image {
    margin-bottom: 4rem;
    max-height: 10rem;
  }

  &__heading {
    font-size: 1.6rem;
    font-weight: bold;
    color: var(--color-dark-1);

    margin-bottom: 1.2rem;
  }

  &__text {
    font-size: 1.6rem;
    color: var(--color-dark-1);
    line-height: 2rem;

    max-width: 30rem;
    text-align: center;
  }
}
