* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;

  // > 1300px and < 1800px
  font-size: 62.5%; // 1rem = 10px

  // < 900px
  @include respond(tablet) {
    font-size: 62.5%; // 1rem = 10px
  }

  // > 1800px
  @include respond(big) {
    font-size: 75%; // 1rem = 12px
  }

  background-color: var(--color-base-ground);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  cursor: default;
  color: var(--color-text);
}

p > a {
  cursor: pointer;
}

a > span,
a > h2,
a > h4 {
  cursor: pointer;
}

::selection {
  background-color: var(--color-primary);
  color: var(--color-light-1);
}
